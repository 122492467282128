<template>
  <div>
    <v-row v-for="(flt, ind) in filters" :key="flt.id" dense class="text-no-wrap" align="center">
      <v-col class="text-right grey--text text-truncate" cols="2" sm="2" md="1" lg="1">
        {{ $t("Câmp") + " " + (ind + 1) + ":" }}
      </v-col>
      <v-col cols="12" sm="3" md="4" lg="4">
        <v-autocomplete
          v-model="flt.field"
          outlined
          dense
          hide-details
          :label="$t('Câmp')"
          :items="filtersFields"
          @change="
            flt.type = filtersTypes[fieldTypes[flt.field]][0].value
            flt.value1 = ''
            flt.label = ''
          "
        />
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-autocomplete
          v-model="flt.type"
          auto-select-first
          outlined
          dense
          hide-details
          :label="$t('Tip căutare')"
          :items="filtersTypes[fieldTypes[flt.field]]"
        />
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4">
        <v-select
          v-if="fieldTypes[flt.field] == 'boolean' || flt.type == 'i'"
          v-model="flt.value1"
          append-icon=""
          clearable
          dense
          hide-details
          :items="booleanValues"
        />
        <v-text-field
          v-else-if="flt.type == 'r=' || flt.type == 'r>' || flt.type == 'r<'"
          v-model="flt.value1"
          clearable
          dense
          hide-details
          :label="$t('Termeni de căutare')"
        />
        <!-- <s-autocomplete
              v-else
              v-model="flt.value1"
              name="sautoelse"
              :view-type="viewType"
              :label="$t('Termeni de căutare')"
              :item-text="flt.field"
              :item-value="flt.field"
              :collection="'ebib_' + collection"
            /> -->
        <v-text-field v-else v-model="flt.value1" clearable dense hide-details :label="$t('Termeni de căutare')" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import SAutocomplete from "../../../components/inputs/Autocomplete"

export default {
  name: "DashboardDashboard",
  components: {},
  props: {
    prefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      domains: [],
      records: [],
      domain: "mon",
      collection: "",
      viewType: "marc",
      showFilters: true,
      filters: [
        { field: "", type: "c", value1: "", value2: "", value3: {}, label: "", id: 0 },
        { field: "", type: "c", value1: "", value2: "", value3: {}, label: "", id: 1 },
        { field: "", type: "c", value1: "", value2: "", value3: {}, label: "", id: 2 }
      ],
      filtersWhere: "",
      advancedSearchFilters: [],
      filtersFields: [],
      fieldTypes: {},
      filtersTypes: {
        string: [
          {
            text: "Conține",
            value: "c"
          },
          {
            text: "Începe cu",
            value: "b"
          },
          {
            text: "Se termină cu",
            value: "e"
          },
          {
            text: "Exact",
            value: "="
          }
        ],
        integer: [
          {
            text: "Egal",
            value: "="
          },
          {
            text: "Mai mare decât",
            value: ">"
          },
          {
            text: "Mai mic decât",
            value: "<"
          }
        ],
        float: [
          {
            text: "Egal",
            value: "="
          },
          {
            text: "Mai mare decât",
            value: ">"
          },
          {
            text: "Mai mic decât",
            value: "<"
          }
        ],
        number: [
          {
            text: "Egal",
            value: "="
          },
          {
            text: "Mai mare decât",
            value: ">"
          },
          {
            text: "Mai mic decât",
            value: "<"
          }
        ],
        datetime: [
          {
            text: "Egal",
            value: "="
          },
          {
            text: "Mai mare decât",
            value: ">"
          },
          {
            text: "Mai mic decât",
            value: "<"
          }
        ],
        boolean: [
          {
            text: "Egal",
            value: "="
          }
        ],
        list: [
          {
            text: "Există",
            value: "i"
          },
          {
            text: "Repetări =",
            value: "r="
          },
          {
            text: "Repetări <",
            value: "r<"
          },
          {
            text: "Repetări >",
            value: "r>"
          }
        ],
        objectid: [
          {
            text: "Egal",
            value: "="
          },
          {
            text: "Există",
            value: "i"
          }
        ]
      },
      booleanValues: [
        {
          text: "Adevărat",
          value: true
        },
        {
          text: "Fals",
          value: false
        }
      ],
      fieldSchema: {}
    }
  },

  computed: {
    categories() {
      const cat = this.$store.getters.confPrefixes.filter(
        itm => "marc_" + itm.prefix == this.collection && itm.category != ""
      )
      cat.sort((a, b) => {
        return a.ordPref - b.ordPref
      })
      return [{ lblRo: "Toate", category: "" }, ...cat]
    },
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  watch: {
    prefix() {
      this.$log("prefix changed ", this.prefix)
      this.changeDomain(this.prefix)
    }
  },
  created() {
    this.$log("prefix created ", this.prefix)
    this.changeDomain(this.prefix)
  },
  methods: {
    searchAdvanced() {
      //this.showFilters = false
      //this.$log("searchAdvanced")
      this.filtersWhere = ""
      this.advancedSearchFilters = []
      this.filters.map(itm => {
        //this.$log("searchAdvanced map ", itm)
        if (itm.value1 !== "" && itm.value1 != undefined) {
          const res = this.getFilter(itm)
          const dic = { ...itm }
          dic.str = res.str
          if (this.filtersWhere != "") {
            this.filtersWhere += "," + res.filter
          } else {
            this.filtersWhere = res.filter
          }
          dic.value1 = encodeURIComponent(dic.value1)
          this.advancedSearchFilters.push(dic)
        }
      })
      //this.$log("filters f ", this.filtersWhere)
      //this.$log("filters a ", this.advancedSearchFilters)
      //this.showFilters = false
      //this.$log("this.page ", this.page)
    },

    getFilter(itm) {
      let str = ""
      let filter = ""
      let label = itm.value1
      itm.value1 = encodeURIComponent(itm.value1)
      if (itm.label != "" && itm.label != undefined) {
        label = itm.label
      }
      this.$log("searchAdvanced  ", itm)
      const arr = itm.field.split(".")
      switch (itm.type) {
        case "c":
          // if (this.fieldTypes[itm.field] == "list") {
          //   this.filtersWhere = '"' + itm.field + '":"' + itm.value1 + '"'
          // } else {
          filter = '"' + itm.field + '":{"$regex":"' + itm.value1 + '","$options":"i"}'
          //this.$log("this.filtersFields  ", this.filtersFields)
          if (this.filtersFields.length > 0) {
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " = ..." + label + "..."
          } else {
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " = ..." + label + "..."
          }
          //}
          break
        case "b":
          filter = '"' + itm.field + '":{"$regex":"^' + itm.value1 + '","$options":"i"}'
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " = " + label + "..."
          break

        case "e":
          filter = '"' + itm.field + '":{"$regex":"' + itm.value1 + '$","$options":"i"}'
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " = ..." + label
          break
        case "i":
          filter = '"' + itm.field + '":{"$exists":' + itm.value1 + "}"
          if (itm.value1 == "true") {
            itm.value1 = true
            str += this.filtersFields.find(flt => flt.value == itm.field).text + ": există"
          } else {
            itm.value1 = false
            str += this.filtersFields.find(flt => flt.value == itm.field).text + ": nu există"
          }
          break
        case "r=":
          if (arr[0] == "fields" && arr[3] == "val") {
            filter =
              '"' +
              itm.field.substring(0, itm.field.length - 4) +
              "." +
              (Number(itm.value1) - 1) +
              '":{"$exists":true}' +
              ',"' +
              itm.field.substring(0, itm.field.length - 4) +
              "." +
              itm.value1 +
              '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []= " + label
          } else {
            filter =
              '"' +
              itm.field +
              "." +
              (Number(itm.value1) - 1) +
              '":{"$exists":true}' +
              ',"' +
              itm.field +
              "." +
              itm.value1 +
              '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []= " + label
          }
          break
        case "r<":
          if (arr[0] == "fields" && arr[3] == "val") {
            filter =
              '"' +
              itm.field.substring(0, itm.field.length - 4) +
              "." +
              (Number(itm.value1) - 1) +
              '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []< " + label
          } else {
            filter = '"' + itm.field + "." + (Number(itm.value1) - 1) + '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []< " + label
          }
          break
        case "r>":
          if (arr[0] == "fields" && arr[3] == "val") {
            filter = '"' + itm.field.substring(0, itm.field.length - 4) + "." + itm.value1 + '":{"$exists":true}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []> " + label
          } else {
            filter = '"' + itm.field + "." + itm.value1 + '":{"$exists":true}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []> " + label
          }
          break
        case "=":
          if (
            this.fieldTypes[itm.field] == "integer" ||
            this.fieldTypes[itm.field] == "float" ||
            this.fieldTypes[itm.field] == "number" ||
            this.fieldTypes[itm.field] == "boolean"
          ) {
            filter = '"' + itm.field + '":' + itm.value1
          } else if (this.fieldTypes[itm.field] == "datetime" || this.fieldTypes[itm.field] == "objectid") {
            filter = '"' + itm.field + '":"' + itm.value1 + '"'
          } else {
            filter = '"' + itm.field + '":{"$regex":"^' + itm.value1 + '$","$options":"i"}'
          }
          this.$log("flt1 ", this.filtersFields)
          this.$log("flt2 ", itm.field)
          this.$log("flt3 ", this.filtersFields.find(flt => flt.value == itm.field).text)
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " = " + label
          break
        case ">":
          if (
            this.fieldTypes[itm.field] == "integer" ||
            this.fieldTypes[itm.field] == "float" ||
            this.fieldTypes[itm.field] == "number"
          ) {
            filter = '"' + itm.field + '":{"$gt":' + itm.value1 + "}"
          } else {
            filter = '"' + itm.field + '":{"$gt":"' + itm.value1 + '"}'
          }
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " > " + label
          break
        case "<":
          if (
            this.fieldTypes[itm.field] == "integer" ||
            this.fieldTypes[itm.field] == "float" ||
            this.fieldTypes[itm.field] == "number"
          ) {
            filter = '"' + itm.field + '":{"$lt":' + itm.value1 + "}"
          } else {
            filter = '"' + itm.field + '":{"$lt":"' + itm.value1 + '"}'
          }
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " < " + label
          break
      }
      itm.value1 = decodeURIComponent(itm.value1)
      return { str, filter }
    },
    changeDomain(e, query) {
      this.$log("change dom ", e)
      this.$log("change dom ", this.collection)
      const chg = this.collection != "marc_" + e.split(".")[0]
      this.collection = "marc_" + e.split(".")[0]
      this.filtersFields = []
      this.domain = e
      this.generateListFields(this.appSchema.filter(itm => itm.collection == this.collection)[0].content, "")
      if (chg) {
        switch (this.collection) {
          case "marc_art":
            this.$set(this.filters[0], "field", "fields.200.a.val")
            this.$set(this.filters[1], "field", "fields.300.a.val")
            this.$set(this.filters[2], "field", "fields.102.a.val")
            break
          case "marc_ath":
            this.$set(this.filters[0], "field", "fields.200.a.val")
            this.$set(this.filters[1], "field", "fields.400.a.val")
            this.$set(this.filters[2], "field", "fields.500.a.val")
            break
          case "marc_mon":
            this.$set(this.filters[0], "field", "fields.200.a.val")
            this.$set(this.filters[1], "field", "fields.700.a.val")
            this.$set(this.filters[2], "field", "fields.606.a.val")
            break
          case "marc_udc":
            this.$set(this.filters[0], "field", "fields.200.a.val")
            this.$set(this.filters[1], "field", "fields.100.a.val")
            this.$set(this.filters[2], "field", "fields.201.a.val")
            break
          case "marc_inv":
            this.$set(this.filters[0], "field", "fields.200.a.val")
            this.$set(this.filters[1], "field", "fields.100.a.val")
            this.$set(this.filters[2], "field", "fields.501.a.val")
            break
        }
        this.$set(this.filters[0], "type", this.filtersTypes[this.fieldTypes[this.filters[0].field]][0].value)
        this.$set(this.filters[1], "type", this.filtersTypes[this.fieldTypes[this.filters[1].field]][0].value)
        this.$set(this.filters[2], "type", this.filtersTypes[this.fieldTypes[this.filters[2].field]][0].value)
        this.$set(this.filters[0], "value1", "")
        this.$set(this.filters[1], "value1", "")
        this.$set(this.filters[2], "value1", "")
        //flt.type =
      }
      if (!query) {
        this.searchAdvanced()
      }
    },
    generateListFields(rec, name) {
      //this.$log("view type ", this.viewType)
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateListFields(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].schema) {
            let lbl = newname
            if (rec[prop].meta) {
              lbl = rec[prop].meta.label
            }
            if (rec[prop].type === "list" && rec[prop].schema.type != "dict") {
              this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
              this.fieldTypes[newname] = rec[prop].schema.type
              this.fieldSchema[newname] = rec[prop].schema
            } else if (rec[prop].type === "list") {
              // const flds = newname.split(".")
              // if (this.viewType == "marc" && flds[0] == "fields" && flds.length == 2) {
              //   this.filtersFields.push({ text: flds[1] + " - " + lbl, value: newname, type: rec[prop].type })
              //   this.fieldTypes[newname] = rec[prop].type
              //   this.fieldSchema[newname] = rec[prop]
              // } else if (this.viewType != "marc") {
              //   this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
              //   this.fieldTypes[newname] = rec[prop].type
              //   this.fieldSchema[newname] = rec[prop]
              // }
            }
            this.generateListFields(rec[prop].schema, newname)
          } else {
            if (rec[prop].type) {
              if (
                (this.viewType == "marc" &&
                  prop != "ctg" &&
                  prop != "id" &&
                  prop != "company" &&
                  prop != "ind1" &&
                  prop != "ind2" &&
                  prop != "ord" &&
                  prop != "link" &&
                  prop != "src_id") ||
                this.viewType != "marc"
              ) {
                let lbl = newname
                if (rec[prop].meta) {
                  lbl = rec[prop].meta.label
                }
                if (this.viewType == "marc") {
                  //this.$log("viretype marc flds ", newname)
                  const flds = newname.split(".")
                  if (flds[2] && flds[0] == "fields" && flds[3] == "val") {
                    lbl = flds[1] + flds[2] + " - " + lbl
                  } else if (flds[0] == "fields" && (flds[2] == "ind1" || flds[2] == "ind2")) {
                    lbl = flds[1] + " - " + lbl
                  }
                }
                this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
                this.fieldTypes[newname] = rec[prop].type
                this.fieldSchema[newname] = rec[prop]
                this.fieldSchema[newname].lbl = lbl
              }
            }
          }
        }
      }
    }
  }
}
</script>
<style></style>
