<template>
  <!-- <v-container id="dashboard" class="pt-1 pb-1" fluid tag="section"> -->
  <v-row>
    <!-- <template v-for="rep in reports">
      <v-col :key="rep.value" cols="12" sm="6" md="4" lg="3">
        <base-material-stats-card
          color="indigo"
          class="mb-0"
          icon="mdi-poll-box"
          :title="$t(rep.label)"
          sub-icon=""
          :sub-text="rep.subtext"
        />
      </v-col>
    </template> -->
    <v-col cols="12">
      <base-material-card inline class="mb-0" icon="mdi-poll-box" :title="$t('Rapoarte inventare')">
        <!-- <v-tabs v-model="rap" vertical>
          <v-tab v-for="rep in reports" :key="rep.value">
            {{ rep.label }}
          </v-tab>
        </v-tabs> -->
        <v-row dense>
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-navigation-drawer permanent>
              <v-list dense nav two-line class="pl-1">
                <v-list-item-group v-model="report" mandatory color="primary">
                  <template v-for="rep in reports">
                    <v-list-item :key="rep.value" dense>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t(rep.label) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $t(rep.subtext) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-divider v-if="index < reports.length - 1" :key="index"></v-divider> -->
                  </template>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="10">
            <v-tabs-items v-model="report">
              <v-tab-item @keydown="keyDown">
                <v-overlay v-model="loading" opacity="0.2" absolute>
                  <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <v-form ref="riiForm">
                  <v-row dense class="text-no-wrap" align="center">
                    <v-col cols="12" class="d-flex pb-0">
                      <v-subheader v-show="!searchAdvanced">
                        {{ $t("Introduceți date pentru căutare") + ":" }}
                      </v-subheader>
                      <v-subheader v-show="searchAdvanced">{{ $t("Căutare avansată") + ":" }}</v-subheader>
                      <v-spacer />
                      <v-btn v-show="!searchAdvanced" color="primary" text outlined @click="searchAdvanced = true">
                        {{ $t("Comută la căutare avansată") }}
                      </v-btn>
                      <v-btn v-show="searchAdvanced" color="primary" text outlined @click="searchAdvanced = false">
                        {{ $t("Înapoi la căutarea simplă") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-show="!searchAdvanced" dense class="text-no-wrap" align="center">
                    <v-col cols="12" lg="6">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          <b>{{ $t("Barcode") + ": " }}</b>
                          {{ $t("de la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-text-field v-model="riiBarcodeStart" dense />
                        </v-col>
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="2" md="2" lg="2">
                          {{ $t("până la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-text-field v-model="riiBarcodeEnd" dense />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          <b>{{ $t("Nr. inventar") + ": " }}</b>
                          {{ $t("de la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-text-field v-model="riiInventarStart" :rules="inputValidate" dense />
                        </v-col>
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="2" md="2" lg="2">
                          {{ $t("până la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-text-field v-model="riiInventarEnd" :rules="inputValidate" dense />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          {{ $t("Gestiune") + ":" }}
                        </v-col>
                        <v-col cols="7" sm="8" md="8" lg="8">
                          <v-autocomplete
                            v-model="riiGestiune"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="gestiuni"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          {{ $t("Regim") + ":" }}
                        </v-col>
                        <v-col cols="7" sm="8" md="8" lg="8">
                          <v-autocomplete
                            v-model="riiRegim"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="regime"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          {{ $t("Categorie inventar") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-autocomplete
                            v-model="riiCatInventar"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="catsInventar"
                          />
                        </v-col>
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="2" md="2" lg="2">
                          {{ $t("Categorie titlu") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-autocomplete
                            v-model="riiCatTitlu"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="catsTitlu"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          <b>{{ $t("RMF") + ": " }}</b>
                          {{ $t("de la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <s-autocomplete
                            v-model="riiRMFStart"
                            dense
                            :clearable="false"
                            view-type="marc"
                            item-text="fields.200.a.val"
                            item-value="fields.200.a.val"
                            collection="ebib_marc_rmf"
                            type="v-autocomplete"
                          />
                        </v-col>
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="2" md="2" lg="2">
                          {{ $t("până la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <s-autocomplete
                            v-model="riiRMFEnd"
                            dense
                            :clearable="false"
                            view-type="marc"
                            item-text="fields.200.a.val"
                            item-value="fields.200.a.val"
                            collection="ebib_marc_rmf"
                            type="v-autocomplete"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-show="searchAdvanced" dense class="text-no-wrap mt-2" align="center">
                    <v-col class="text-right grey--text text-truncate" cols="2" sm="2" md="1" lg="1">
                      {{ $t("Categorie") + " :" }}
                    </v-col>
                    <v-col cols="12" sm="3" md="4" lg="4">
                      <v-autocomplete
                        v-model="riiCatInventarAdv"
                        chips
                        deletable-chips
                        small-chips
                        hide-details
                        dense
                        multiple
                        outlined
                        :label="$t('Categorie')"
                        item-text="name"
                        item-value="value"
                        :items="catsInventar"
                      />
                    </v-col>
                    <v-col cols="12">
                      <search-advanced prefix="inv" />
                    </v-col>
                  </v-row>
                  <v-row dense class="text-no-wrap" align="center">
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                    <v-col cols="12" class="d-flex flex-wrap">
                      <v-btn color="primary" class="ml-2 mt-2" @click="getRapRII">{{ $t("Generează raport") }}</v-btn>
                      <v-btn v-if="reportRec" class="ml-2 mt-2" text outlined color="primary" @click="previewReport">
                        {{ $t("Previzualizare") }}
                      </v-btn>
                      <v-btn v-if="reportRec" class="ml-2 mt-2" text outlined color="info" @click="downloadReport">
                        {{ $t("Descarcă") }}
                      </v-btn>
                      <v-edit-dialog
                        v-if="reportRec"
                        large
                        :save-text="$t('Șterge')"
                        :cancel-text="$t('Anulează')"
                        @save="deleteReport"
                      >
                        <v-btn class="ml-2 mt-2" text outlined color="error">
                          {{ $t("Șterge") }}
                        </v-btn>
                        <template #input>
                          <v-sheet>
                            <v-card-title>
                              {{ $t("Sigur doriți să ștergeți?") }}
                            </v-card-title>
                          </v-sheet>
                        </template>
                      </v-edit-dialog>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-wrap mt-2 pl-4">
                      {{ reportRec.name }}
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                2
              </v-tab-item>
              <v-tab-item>
                <v-form ref="icaForm">
                  <v-row dense class="text-no-wrap" align="center">
                    <v-col cols="12" class="d-flex pb-0">
                      <v-subheader v-show="!searchAdvanced">
                        {{ $t("Intrare documente după... [Conținut/Categorie și Limbă]") }}
                      </v-subheader>
                      <v-subheader v-show="searchAdvanced">{{ $t("Căutare avansată") + ":" }}</v-subheader>
                      <v-spacer />
                      <v-btn v-show="!searchAdvanced" color="primary" text outlined @click="searchAdvanced = true">
                        {{ $t("Comută la căutare avansată") }}
                      </v-btn>
                      <v-btn v-show="searchAdvanced" color="primary" text outlined @click="searchAdvanced = false">
                        {{ $t("Înapoi la căutarea simplă") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-show="!searchAdvanced" dense class="text-no-wrap" align="center">
                    <v-col cols="12" lg="10">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          <b>{{ $t("RMF") + ": " }}</b>
                          {{ $t("de la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <s-autocomplete
                            v-model="icaRMFStart"
                            dense
                            :clearable="false"
                            view-type="marc"
                            item-text="fields.200.a.val"
                            item-value="fields.200.a.val"
                            collection="ebib_marc_rmf"
                            type="v-autocomplete"
                          />
                        </v-col>
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="2" md="2" lg="2">
                          {{ $t("până la") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <s-autocomplete
                            v-model="icaRMFEnd"
                            dense
                            :clearable="false"
                            view-type="marc"
                            item-text="fields.200.a.val"
                            item-value="fields.200.a.val"
                            collection="ebib_marc_rmf"
                            type="v-autocomplete"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="10">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          {{ $t("Gestiune") + ":" }}
                        </v-col>
                        <v-col cols="7" sm="8" md="8" lg="8">
                          <v-autocomplete
                            v-model="icaGestiune"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="gestiuni"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="10">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          {{ $t("Regim") + ":" }}
                        </v-col>
                        <v-col cols="7" sm="8" md="8" lg="8">
                          <v-autocomplete
                            v-model="icaRegim"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="regime"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="10">
                      <v-row dense class="text-no-wrap" align="center">
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="4" md="3" lg="4">
                          {{ $t("Categorie inventar") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-autocomplete
                            v-model="icaCatInventar"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="catsInventar"
                          />
                        </v-col>
                        <v-col class="text-right grey--text text-truncate" cols="5" sm="2" md="2" lg="2">
                          {{ $t("Categorie titlu") }}
                        </v-col>
                        <v-col cols="7" sm="3" md="3" lg="3">
                          <v-autocomplete
                            v-model="icaCatTitlu"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                            item-text="name"
                            item-value="value"
                            :items="catsTitlu"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-show="searchAdvanced" dense class="text-no-wrap mt-2" align="center">
                    <v-col class="text-right grey--text text-truncate" cols="2" sm="2" md="1" lg="1">
                      {{ $t("Categorie") + " :" }}
                    </v-col>
                    <v-col cols="12" sm="3" md="4" lg="4">
                      <v-autocomplete
                        v-model="icaCatInventarAdv"
                        chips
                        deletable-chips
                        small-chips
                        hide-details
                        dense
                        multiple
                        outlined
                        :label="$t('Categorie')"
                        item-text="name"
                        item-value="value"
                        :items="catsInventar"
                      />
                    </v-col>
                    <v-col cols="12">
                      <search-advanced prefix="inv" />
                    </v-col>
                  </v-row>
                  <v-row dense class="text-no-wrap" align="center">
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                    <v-col cols="12" class="d-flex flex-wrap">
                      <v-btn color="primary" class="ml-2 mt-2" @click="getRapRII">{{ $t("Generează raport") }}</v-btn>
                      <v-btn v-if="reportRec" class="ml-2 mt-2" text outlined color="primary" @click="previewReport">
                        {{ $t("Previzualizare") }}
                      </v-btn>
                      <v-btn v-if="reportRec" class="ml-2 mt-2" text outlined color="info" @click="downloadReport">
                        {{ $t("Descarcă") }}
                      </v-btn>
                      <v-edit-dialog
                        v-if="reportRec"
                        large
                        :save-text="$t('Șterge')"
                        :cancel-text="$t('Anulează')"
                        @save="deleteReport"
                      >
                        <v-btn class="ml-2 mt-2" text outlined color="error">
                          {{ $t("Șterge") }}
                        </v-btn>
                        <template #input>
                          <v-sheet>
                            <v-card-title>
                              {{ $t("Sigur doriți să ștergeți?") }}
                            </v-card-title>
                          </v-sheet>
                        </template>
                      </v-edit-dialog>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-wrap mt-2 pl-4">
                      {{ reportRec.name }}
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                4
              </v-tab-item>
              <v-tab-item>
                5
              </v-tab-item>
              <v-tab-item>
                6
              </v-tab-item>
              <v-tab-item>
                7
              </v-tab-item>
              <v-tab-item>
                8
              </v-tab-item>
              <v-tab-item>
                9
              </v-tab-item>
              <v-tab-item>
                10
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </base-material-card>
    </v-col>
    <v-dialog v-model="preview" scrollable persistent>
      <v-card>
        <v-card-title class="pl-4 pr-2 pt-2 ">
          {{ reportRec.name }}
          <v-spacer />
          <v-icon color="red" large @click="preview = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-sheet
            style="overflow:auto"
            class="v-data-table--dense v-data-table__wrapper ratab"
            height="100%"
            width="100%"
            v-html="docHtml"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="preview = false">{{ $t("Închide") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <!-- </v-container> -->
</template>
<script>
import SAutocomplete from "../../../components/inputs/Autocomplete"
import SearchAdvanced from "../../../components/containers/SearchAdvancedForm"
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {
    SAutocomplete,
    SearchAdvanced
  },
  data() {
    return {
      loading: false,
      preview: false,
      searchAdvanced: false,
      reportRec: "",
      docHtml: "",
      srcData: "",
      srcIframe: "",
      riiInventarStart: "1",
      riiInventarEnd: "9999999",
      riiBarcodeStart: "",
      riiBarcodeEnd: "",
      riiRMFStart: "",
      riiRMFEnd: "",
      riiGestiune: [],
      riiRegim: [],
      riiCatTitlu: [],
      riiCatInventar: [],
      riiCatInventarAdv: null,
      icaRMFStart: "",
      icaRMFEnd: "",
      icaGestiune: [],
      icaRegim: [],
      icaCatTitlu: [],
      icaCatInventar: [],
      icaCatInventarAdv: null,
      title: "",
      rap: "",
      report: 0,
      path: "",
      reports: [
        {
          label: "Registru inventar",
          subtext: "",
          val: "rii"
        },
        // {
        //   label: "Registru inventar (monografii)",
        //   subtext: "Filtru pe monografii",
        //   val: "rim"
        // },
        // {
        //   label: "Registru inventar (RMF)",
        //   subtext: "Filtru pe RMF-uri",
        //   val: "rir"
        // },
        {
          label: "Proces verbal",
          subtext: "",
          val: "pv"
        },
        {
          label: "Intrare dococumente. după conținut",
          subtext: "",
          val: "ica"
        },
        {
          label: "Recepție inventare",
          subtext: "",
          val: "ri"
        },
        {
          label: "Proces verval predare primire",
          subtext: "Filtru inventar",
          val: "ppi"
        },
        {
          label: "Proces verval predare primire",
          subtext: "Filtru RMF",
          val: "ppr"
        },
        {
          label: "Situație inventare pe RMF",
          subtext: "",
          val: "sr"
        }
      ]
      // menu: [],
    }
  },
  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    appType() {
      return this.$store.getters.appType
    },
    accountType() {
      return this.$store.getters.accountLevel
    },
    gestiuni() {
      return this.$store.getters.dictionary("all_gestiune")
    },
    regime() {
      return this.$store.getters.dictionary("all_regimImprumut")
    },
    catsTitlu() {
      return this.$store.getters.dictionary("all_categorii_mon")
    },
    catsInventar() {
      return this.$store.getters.dictionary("all_categorii_inv")
    }
  },
  watch: {
    $route(to) {
      //this.$log("route to ", to)
      if (to.path == this.path) {
      }
    }
  },
  created() {
    this.path = this.$route.path
  },
  methods: {
    keyDown(e) {
      if (e.key == "F8") {
        this.$log("reset")
        this.riiInventarStart = "1"
        this.riiInventarEnd = "9999999"
        this.riiBarcodeStart = ""
        this.riiBarcodeEnd = ""
        this.riiRMFStart = ""
        this.riiRMFEnd = ""
        this.riiGestiune = []
        this.riiRegim = []
        this.riiCatTitlu = []
        this.riiCatInventar = []
        this.riiCatInventarAdv = null
        this.$refs.riiForm.resetValidation()
      }
    },
    getRapRII() {
      if (this.$refs.riiForm.validate()) {
        this.loading = true
        this.reportRec = ""
        const rec = { "inv/100/a": { i: [this.riiInventarStart, this.riiInventarEnd] } }
        if (this.riiBarcodeStart || this.riiBarcodeEnd) {
          rec["inv/200/a"] = { i: [this.riiBarcodeStart, this.riiBarcodeEnd] }
        }
        if (this.riiRMFStart || this.riiRMFEnd) {
          rec["rmf/200/a"] = { i: [this.riiRMFStart, this.riiRMFEnd] }
        }
        if (this.riiGestiune.length > 0) {
          rec["inv/500/a"] = { e: this.riiGestiune }
        }
        if (this.riiRegim.length > 0) {
          rec["inv/505/a"] = { e: this.riiRegim }
        }
        if (this.riiCatInventar.length > 0) {
          rec["inv/ctg"] = { e: this.riiCatInventar }
        }
        if (this.riiCatTitlu.length > 0) {
          rec["mon/ctg"] = { e: this.riiCatTitlu }
        }
        //this.$log("rec ", JSON.stringify(rec))
        axios
          .post("exec_report/r100_registru_inventar/docx", { query: { $and: rec } })
          .then(res => {
            this.loading = false
            //this.rapJson = res.data
            //this.$log("dashboard new ", res)
            if (res.status == 200) {
              axios.get('ebib_marc_doc/?where={"fields.200.a.val":"' + res.data.report_name + '"}').then(rap => {
                this.$log("rap", rap)
                if (rap.data._items[0]) {
                  this.reportRec = rap.data._items[0]
                  this.reportRec.name = res.data.report_name
                }
              })
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    previewReport() {
      this.loading = true
      axios
        .get("download_doc/" + this.reportRec._id, {
          // responseType: "blob"
          responseType: "arraybuffer"
        })
        .then(rap => {
          this.loading = false
          //this.$log("resdes ", rap)
          if (rap.status == 200) {
            this.preview = true
            var mammoth = require("mammoth")
            mammoth
              .convertToHtml({ arrayBuffer: rap.data })
              .then(result => {
                var html = result.value // The generated HTML
                var messages = result.messages // Any messages, such as warnings during conversion
                this.docHtml = html
                this.$log(messages)
              })
              .done()
            //const reader = new FileReader()
            // reader.addEventListener(
            //   "load",
            //   () => {
            //     this.srcData = reader.result
            //   },
            //   false
            // )
            //reader.readAsDataURL(rap.data)
            // this.srcIframe = "https://view.officeapps.live.com/op/embed.aspx?src=" + url
            //this.srcIframe = "https://docs.google.com/gview?embedded=true&url=" + reader.result
            //this.srcIframe = "https://view.officeapps.live.com/op/embed.aspx?src=" + reader.result
            // https://docs.google.com/gview?url=https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx&embedded=true
            // https://view.officeapps.live.com/op/embed.aspx?src=https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx
            //var url = window.URL.createObjectURL(new Blob([rap.data], { type: rap.headers["content-type"] }))
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    downloadReport() {
      axios
        .get("download_doc/" + this.reportRec._id, {
          responseType: "blob"
        })
        .then(rap => {
          if (rap.status == 200) {
            var a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            var url = window.URL.createObjectURL(rap.data)
            a.href = url
            a.download = this.reportRec.fields["200"][0].a[0].val
            a.click()
            document.body.removeChild(a)
          }
        })
    },
    deleteReport() {
      this.$log("deleteReport")
      axios
        .delete("ebib_marc_doc/" + this.reportRec._id, {
          headers: {
            "If-Match": this.reportRec._etag
          }
        })
        .then(res => {
          this.$log("res ", res)
          if (res.status == 204) {
            this.reportRec = ""
          }
        })
    }
  }
}
</script>
<style>
.ratab table {
  border-collapse: collapse;
}
.ratab td,
.ratab th {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
</style>
